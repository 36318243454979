(function () {
	// body...
	'use strict';

	var ResponsiveTable = {
		GetAjaxData: function(data, id) {
			var self = this;
			if(data) {
				window.ResponsiveJSON = data;
				window.ResponsiveModalJSON = data;
			 	self.RenderCarousel(data, id);
			 	self.RenderModal(data, id);
				//} 
			}
		},
		RenderModal: function(data, id) {
			var ModalId = $(id).attr('data-modal'),
				Parent = $('#' + ModalId),
				HeaderData = data[0],
				Header = "",
				category = "",
				CreateList = window.jsonMappingData;

			
			Parent.find('.table').empty();
			var Header = "<div class='tableHead' data-head='Company'><strong>Company</strong><a href='#' class='sort-modal' type='ascending'><svg class='sorting-arrows__arrow sorting-arrows__arrow--down'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg></a><a href='#' class='sort-modal' type='descending'><svg class='sorting-arrows__arrow sorting-arrows__arrow--down'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg></a></div>";
			for(var key in CreateList) {
					Header+="<div class='tableHead' data-head='"+ CreateList[key].Key +"'><strong>" + CreateList[key].Value + "</strong><a href='#' class='sort-modal' type='ascending'><svg class='sorting-arrows__arrow sorting-arrows__arrow--down'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg></a><a href='#' class='sort-modal' type='descending'><svg class='sorting-arrows__arrow sorting-arrows__arrow--down'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg></a></div>";
			}
			Parent.find('.table').append('<div class="tableRow">' + Header + '</div>');
			for(var key1 in data) {
				var Item = data[key1],
					Template = "";
				for(var val in Item) {
					var content = "";
					if(val !== "ID") {
						if(val !== "CompanyPageUrl") {
							if(Array.isArray(Item[val])) {
								content = Item[val][0].value;
							} else {
								content = Item[val];
							}
							Template += "<div class='tableCell'>" + content + "</div>";
						}
					}
				}
				Parent.find('.table').append('<div class="tableRow">' + Template + '</div>');
			}

		},
		RenderCarousel: function(data, Parent) {
			
			Parent.find('.owl-carousel').remove();
			Parent.find('.states_heading').find('.RB16').remove();
			Parent.find('.states_heading').after('<div class="owl-wrapper"><div class="owl-carousel"></div></div>');
			var CreateList = window.jsonMappingData;

			for(var key in CreateList) {
				Parent.find('.owl-carousel').append('<div class="article" data-head="' +CreateList[key].Key+ '"><div class="year_heading"><span>' + CreateList[key].Value + '</span><a href="#" class="sort" type="ascending"><svg class="sorting-arrows__arrow sorting-arrows__arrow--down"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/img/svg-sprite.svg#sorting-arrow-table"></use></svg></a><a href="#" class="sort" type="descending"><svg class="sorting-arrows__arrow sorting-arrows__arrow--down"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/img/svg-sprite.svg#sorting-arrow-table"></use></svg></a></div></div>');
			}
			var Items = Parent.find('.owl-carousel').find('.article');

			for(var i = 0; i < data.length; i++) {
				var Item = data[i], index = i;
				Parent.find('.states_heading').append('<div class="RB16"><a href="' + data[i].CompanyPageUrl + '">' +data[i].Company+ '</a></div>');
				for(var key1 in Item) {
					if(Array.isArray(Item[key1])) {
						Parent.find('.article[data-head="' +key1+ '"]').append('<div  class="R16 TableRow'+index+'">' +Item[key1][0].value+ '</div>');
					}
				}
			}
			this.InitiateCarousel(Parent);
			this.HeightManagement(Parent);
			
		},
		HeightManagement: function(Parent) {
			var HeadingItems = Parent.find('.year_heading'),
				MaxHeadingHeight = 0;

			Parent.find('.R16').height('auto');
			Parent.find('.states_heading .RB16').each(function(key){
				var Height = $(this).height(),
					Item = [];

					$('.article').each(function() {
						var CurrentItem = $(this).find('.R16')[key];
						Item.push(CurrentItem);
					});


				for(var i = 0; i < Item.length; i++) {
					$(Item[i]).height(Height);
				}
			});
			// HeadingItems.height(0);
			HeadingItems.each(function() {
				var thisHeight = $(this).height();
				if(thisHeight > MaxHeadingHeight) {
					MaxHeadingHeight = thisHeight;
				}
			});
			$(HeadingItems).find('span').height(MaxHeadingHeight);
		},
		InitiateCarousel: function(Parent) {

			
			Parent.find('.owl-carousel').owlCarousel({
               loop:false,
               margin:0,
               merge:true,
               nav:false,
			   slideBy: 4,  
               responsive:{
               0:{
               items:4
               },
               678:{
               items:2
               },
               320:{
                items:2
               },
               480:{
                items:2
               },
               1024:{
               items:3
               },
               1025:{
               items:4
               }
               }
            });
        },
        ModalEvents: function() {
        	$(document).on('click', 'a[data-toggle="modal-annualresults"]', function(e) {
        		e.preventDefault();
        		$('#modal-annualresults').show();
        	});
        	$(document).on('click', '#modal-annualresults .table_close', function(e) {
        		e.preventDefault();
        		$(this).parents('.ID-responsive-table-modal').hide();
        	});
        	$(document).on('click', '.ID-responsive-table-modal', function(e) {
    			if($(e.target).parents('.container').length > 0 || $(e.target).hasClass('.container')) {
    				return false;
    			} else {
    				$(this).hide();
    			}
        	});
        },
        SortingModal: function(id) {
        	var self = this;

        	$(document).on('click','.sort-modal', function(e) {
        		e.preventDefault();
        		var MainData = window.ResponsiveJSON,
        			Index = $(this).parents('.tableHead').index(),
        			Items = [],
        			type = $(this).attr('type'),
        			Category = $(this).attr('category'),
        			ModalData = window.ResponsiveModalJSON,
        			UpdatedJson = [],
        			HeadingText = $(this).parents('.tableHead').attr('data-head');


        		$('#modal-annualresults .tableRow').each(function() {
        			if($(this).find('.tableCell').length > 0) {
	        			var Text = $($(this).find('.tableCell')[Index]).text();
	        			if(HeadingText === 'Company') {
		        			Items.push(Text);
		        		} else {
		        			Items.push(parseFloat(Text));
		        		}
	        			
	        		}
        		});

        		if(HeadingText === 'Company') {
					if(type === "descending") {
						Items.sort().reverse();
					} else {
						Items.sort();
					}

					for(var key in Items) {
						for(var json in ModalData) {
							if(Items[key] === ModalData[json].Company) {
								UpdatedJson.push(ModalData[json]);
							}
						}
					}
				} else {
					if(type === "descending") {
						Items.sort(function(a, b){
						  return b - a;
						});
					} else {
						Items.sort(function(a, b){
						  return a - b;
						});
					}

					for(var key1 in Items) {
						for(var json1 in ModalData) {
							if(Items[key1] == ModalData[json1][HeadingText][0].value) {
								UpdatedJson.push(ModalData[json1]);
							}
						}
					}
				}
				window.ResponsiveModalJSON = UpdatedJson;

				self.RenderModal(window.ResponsiveModalJSON, id);
				$('.sort-modal').removeClass('active');
        		$($('#modal-annualresults .tableRow .tableHead')[Index]).find('.sort-modal[type='+ type +']').addClass('active');
        	});
        },
        SortingFunctionality: function(id) {
        	//Sorting Functionality
        	var self = this;
			$(document).on('click', '.year_heading .sort', function(e) {
				e.preventDefault();
				var Parent = $(this).parents('.article'),
					Values = Parent.find('.R16'),
					Content = Parent.attr('data-head'),
					type = $(this).attr('type'),
					category = $(this).attr('category'),
					Items = [],
					CarouselControl = $(this).parents('.ID-Responsive-Table').find('.owl-controls').find('.owl-dots'),
					ControlIndex = CarouselControl.find('.active').index(),
					CarouselStyles = $('#annualresults .owl-stage').attr('style'),
					OwlItems = $('#annualresults .owl-stage').find('.owl-item'),
					ClonedItems = [],
					ActiveItems = [];

					var stylesArray = CarouselStyles.split(';');
					stylesArray.splice(1, 1);
					CarouselStyles = stylesArray.join(';');

				OwlItems.each(function() {
					if($(this).hasClass('cloned')) {
						ClonedItems.push($(this).index());
					}
					if($(this).hasClass('active')) {
						ActiveItems.push($(this).index());
					}
				});
				$('.year_heading .sort').removeClass('active');
				$(this).addClass('active');

				if(category === 'companies') {
					var CompanyNames = $(this).parents('.states_heading').find('.RB16');

					CompanyNames.each(function() {
						Items.push($(this).text());
					});
					if(type === "descending") {
						Items.sort().reverse();
					} else {
						Items.sort();
					}
				} else {
					Values.each(function() {
						if($(this).length > 0) {
							Items.push(parseFloat($(this).text()));
						}
					});
					if(type === "descending") {
						Items.sort(function(a, b){
						  return b - a;
						});
					} else {
						Items.sort(function(a, b){
						  return a - b;
						});
					}
				}
				
				self.RecreateObject(Content, Items, window.ResponsiveJSON, id, category);
				
				$('#annualresults .owl-stage').attr('style', CarouselStyles);
				$('#annualresults .owl-stage .owl-item').removeClass('cloned');
				$('#annualresults .owl-stage .owl-item').removeClass('active');
				for(var key in ClonedItems) {
					$($('#annualresults .owl-stage .owl-item')[ClonedItems[key]]).addClass('cloned');
				}

				for(var key1 in ActiveItems) {
					$($('#annualresults .owl-stage .owl-item')[ActiveItems[key1]]).addClass('active');
				}

				$('#annualresults .owl-dot').removeClass('active');
				$($('#annualresults .owl-dot')[ControlIndex]).addClass('active');
				$('#annualresults .article[data-head="' + Content + '"] .sort[type="' + type + '"]').addClass('active');
			});
        },
        RecreateObject: function(Content, SortedItem, MainArray, id, category, modal) {
        	var self = this, RecreatedArray = [];
        	// id.find('.RB16').remove();
        	if(category === 'companies') {
        		for(var i = 0; i < SortedItem.length; i++) {
	        		for(var key in MainArray) {
	        			var Name = MainArray[key].Company;
	        			if(Name === SortedItem[i]) {
	        				RecreatedArray.push(MainArray[key]);
	        			}
	        		}
	        	}
        	} else {
	        	for(var j = 0; j < SortedItem.length; j++) {
	        		for(var key1 in MainArray) {
	        			var _Object1 = MainArray[key1];
	        			if(_Object1[Content][0].value == SortedItem[j]) {
	        				RecreatedArray.push(_Object1);
	        			}
	        		}
	        	}
	        	for(var key2 in MainArray) {
        			var _Object2 = MainArray[key2];
        			if(_Object2[Content][0].value.length === 0) {
        				RecreatedArray.push(_Object2);
        			}
        		}
	        }
        	// $owl.trigger('destroy.owl.carousel');
        	
        	if(modal) {
        		window.ResponsiveModalJSON = RecreatedArray;
        		self.RenderModal(window.ResponsiveModalJSON, id);
        	} else {
        		window.ResponsiveJSON = RecreatedArray;
	        	self.RenderCarousel(window.ResponsiveJSON, id);
	        }
        },
		init: function(data, id) {
			var self = this;
			self.GetAjaxData(data, id);
			self.ModalEvents();
			self.SortingFunctionality(id);
			self.SortingModal(id);
			$(window).resize(function() {
				self.HeightManagement(id);
			});
		}
	}

	if($('#annualresults').length > 0) {
		if(window.jsonResultAnnual && Array.isArray(window.jsonResultAnnual)) {
			if(window.jsonResultAnnual.length > 0) {
				ResponsiveTable.init(window.jsonResultAnnual, $('#annualresults'));	
			}
		} else {
			var ErrorMessage = $('#hdnErrormessage').val();
			if(Array.isArray(window.jsonResultAnnual)) {
				if(window.jsonResultAnnual.length === 0) {
	                 var ErrorMessage = $('#hdnInfomessage').val();
	            }
	        }
			$('#annualresults').html('<div class="alert-error js-form-error js-form-error-PasswordRequirements" style="display: block;">'+
											'<svg class="alert__icon">'+
                        						'<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/dist/img/svg-sprite.svg#alert"></use>'+
                    						'</svg>'+
											'<p class="page-account-contact__error">'+
                        						ErrorMessage+
                    						'</p>'+
                						'</div>');
			$('.footnote').remove();
		}
	}
	

	
})();

$(document).on('mouseenter', '.ID-Responsive-Table .R16, .ID-Responsive-Table .RB16', function() {
	var Index = $(this).index();
	$($('.states_heading .RB16')[Index -1]).addClass('activate-hover');
	$('.owl-item').each(function() {
		$($(this).find('.R16')[Index -1]).addClass('activate-hover');
	})
})

$(document).on('mouseleave', '.ID-Responsive-Table .R16, .ID-Responsive-Table .RB16', function() {
	$('.R16, .RB16').removeClass('activate-hover');
})