function removeLabel(){
	setTimeout(function () {
		$("a[title='JavaScript charts']").hide();
		var allshow = $('rect[fill="#e5e5e5"]');
		for(var i=0; i<allshow.length; i++){
			if($(allshow[i]).closest('g').attr('visibility') === 'visible'){
				$(allshow[i]).closest('g').attr('visibility', 'hidden')
			}	
		}
	}, 500); 
}

$(function(){
	$('.availableGraphs').off('click').on('click', 'li a', function () {
		var $this = $(this), id = $this.attr('id'), getFocusId = id.split('-')[1];
		$(window).scrollTop($('#' + getFocusId).offset().top);
		if(!$('#'+getFocusId).find('.chartexpand').hasClass('active')){
			$('#'+getFocusId).find('.chartexpand').trigger('click');
		}
	});
	
	var chartAccordionIDs = ["GWP", "NWP", "UR", "NP", "SF", "NWPNR", "SFNR", "NPSFR"];
	$('.expandAll', '.compareChart').click(function(){
		var eachChartData  = $('.eachChartData');
		for(var i = 0; i < chartAccordionIDs.length; i++){
			if(!$(eachChartData[i]).find('.chartexpand').hasClass('active')){
				$(eachChartData[i]).find('.chartexpand').click();
			}
		}
		$('.chartexpand', '.compareChart').addClass('active');
	});
	
	$('.collapseAll', '.compareChart').click(function(){
		if($('.graph-container') && $('.graph-container').length){
			$('.graph-container', '.compareChart').addClass('hide');
		}
		$('.chartData', '.compareChart').addClass('hide');
		$('.chartexpand', '.compareChart').removeClass('active');
		$('.dataTitle', '.compareChart').removeClass('act');
		$('.title', '.compareChart').removeClass('act');
	});
	
	$('.chartexpand', '.eachChartData').click(function () {
		var $this = $(this), eachChartData = $this.closest('.eachChartData'), chartexpand = eachChartData.find('.chartexpand'), graphCont = eachChartData.find('.graph-container');
		if(chartexpand.hasClass('active')){
			$this.closest('.dataTitle').removeClass('act');
			$this.closest('.dataTitle').find('.title').removeClass('act');
			graphCont.addClass('hide');
			$this.removeClass('active');
		}
		else{
			$this.closest('.dataTitle').addClass('act');
			$this.closest('.dataTitle').find('.title').addClass('act');
			graphCont.removeClass('hide');
			$this.addClass('active');
			removeLabel();
		}
	});
	
	var geturl = window.location.href;
	if(geturl.indexOf('graphid=') !== -1){
		var getId = geturl.split('graphid=')[1];
		$(window).scrollTop($('#' + getId).offset().top);
		$('#'+getId).find('.chartexpand').trigger('click'); 
	}
	
	$('.section-article-img').each(function(idx){
		var $this = $(this), sectionArt = $this.closest('.section-article'), sectionImg = sectionArt.find('.section-article-img');
		if($.trim($(sectionImg).html()) !== '') {
			//sectionTxt.css('display', 'inline');
		}
	});
});

