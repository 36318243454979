$(function() {
    var $body = $('body'),
        $overlay = "<div class='analyst-overlay'></div>";

        $body.append($overlay);
        
        $(".action-flag.pop-out__trigger.js-pop-out-trigger[data-pop-out-type='ask-the-analyst'], .dismiss-button.js-dismiss-ask-the-analyst").on('click', function (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $('.ask-an-analyst, .pop-out.js-pop-out__ask-the-analyst').toggleClass('show').toggleClass('is-active');
            $('.analyst-overlay').removeClass('active');
            $('.js-ask-the-analyst-form-wrapper').show();
            $('.js-ask-the-analyst-success').hide();
            $('.button--filled.click-utag').attr('disabled', 'disabled');
            $('input[type=text]:not([readonly])').val('');
            $(".pop-out__input.pop-out__textarea").val('');
            window.controlPopOuts.closePopOuts();
        });
});