﻿$(document).ready(function () {
    // method to generate GUID
    function getUUID(a) {
        return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, getUUID);
    }

    function getUUIDElement(item, text){
        var uniqueId = getUUID();
                var ele = document.createElement('p');
                var child = document.createElement('a');
                child.href = "#" + uniqueId;
                child.innerText = text;
                child.setAttribute("class", "click-utag");
                child.setAttribute("data-info", JSON.stringify({}));
                item.id = uniqueId;
                ele.appendChild(child);
        return ele;
    }

    var jumpToSection = $("#longReadJumpToSection");
    var longReadWithAssets = $(".longReadWithAssets"); //Used for Omdia
    var longReadContent;
    var longReadFigures;
    var isLongReadWithAssets;
    if(longReadWithAssets.length > 0){
        isLongReadWithAssets = true;
        longReadContent = $(".longread-content");
        longReadFigures = $(".longread-figures");
    }
    var articleBody = $("#main-well");
    var contentsAvailable = false;
    if (articleBody) {
        var headers = $(articleBody).find('h2');
        if (headers && headers.length > 0) {
            headers.each(function (item) {
                var ele = getUUIDElement(headers[item], headers[item].innerText);
                if(isLongReadWithAssets){
                    longReadContent.append(ele);
                    contentsAvailable = true;
                }else{
                    jumpToSection.append(ele); //Legacy code this should work
                }

            })
            if(isLongReadWithAssets){
                var methodology = $(articleBody).find('h5');
                if( methodology && methodology.length > 0){
                    methodology.each(function (item) {
                        if(methodology[item].innerText == "Methodology"){
                            longReadContent.append(getUUIDElement(methodology[item], methodology[item].innerText));
                        }
                    })

                }

                longReadContent.show();
            }
            jumpToSection.show();
        }
        //Omdia specific - Long article sidebar with images links
        if(isLongReadWithAssets){
            var imgs = $(articleBody).find('.enlarge');
        if (imgs && imgs.length > 0) {
            imgs.each(function (item) {
                var ele = getUUIDElement(imgs[item], imgs[item].title);
                longReadFigures.append(ele);
            })
        }
            var downloads = $(".download-btn");
                if( downloads && downloads.length > 0){
                    downloads.each(function (item) {
                        var ele = getUUIDElement(downloads[item], downloads[item].innerText);
                        if(isLongReadWithAssets){
                            longReadFigures.append(ele);
                        }
                    })
                }

        if( imgs.length > 0 || downloads.length > 0 ){
            longReadFigures.show();
        }
        else{
            longReadFigures.hide();
        }


        }
        if(!contentsAvailable){
            if($(".longread-content")){
                $(".longread-content").hide();
            }
        }
    }
});


window.onload = function (e) {
    function CreateHtmlForGoogleAd(advertising) {
        var adRootNode = document.createElement(advertising.parentNode.nodeName.toLocaleLowerCase());
        adRootNode.setAttribute("class", advertising.parentNode.className);

        var adDivNode = document.createElement(advertising.nodeName.toLocaleLowerCase());
        adDivNode.setAttribute("class", advertising.className);

        //Add Advertisement label element
        var adLabelElement = $(advertising).find('.advertising__label');
        if (adLabelElement && adLabelElement.length > 0) {
            adDivNode.innerHTML = adLabelElement[0].outerHTML;
        }
        /*
         *      Input Sample:
         *     <div id='div-gpt-ad-219bede786fe48459718115b39db0497-rect_right1' data-ad-position="[300, 250]"
                     data-ad-zone="home"
                     data-ad-slotid="rect_right1"
                     data-ad-newguid="a09d932372624d38bda6e3527bb1da39"
                     data-ad-enableResponsiveAds="true"
                     data-ad-sizeMappings="[[[0,0], [[300, 250], [300, 600]]]]">
                    <script>
                        Google_Ads('rect_right1', [300, 250], 'div-gpt-ad-219bede786fe48459718115b39db0497-rect_right1', [[[0,0], [[300, 250], [300, 600]]]], true);
                    </script>
                </div>
         */
        var googleAdDiv = $(advertising).find('[id^=div-gpt-ad-]');

        var newDiv = document.createElement(googleAdDiv.prop("tagName"));
        newDiv.setAttribute("id", "div-gpt-ad-" + googleAdDiv.data("ad-newguid") + "-" + googleAdDiv.data("ad-slotid"));

        var scriptTag = document.createElement("script");
        scriptTag.append("var position = " + JSON.stringify(googleAdDiv.data("ad-position")) + ";");
        scriptTag.append("var divId = '" + newDiv.id + "';");
        scriptTag.append("var sizeMappings = " + JSON.stringify(googleAdDiv.data("ad-sizemappings")) + ";");
        scriptTag.append("var enableResponsiveAds = " + googleAdDiv.data("ad-enableresponsiveads") + ";");
        scriptTag.append("Google_Ads('" + googleAdDiv.data("ad-slotid") + "', position, divId, sizeMappings, enableResponsiveAds);");
        newDiv.innerHTML = scriptTag.outerHTML;

        adDivNode.append(newDiv);
        $(adRootNode).append(adDivNode);
        return adRootNode;
    }
    function IgnoreTag(tag) {
        var result = false;
        if (tag.innerHTML == "") {
            result = true;
        }
        var pToSkip = ["Insight-Table-Style", "quick-facts", "js-ignore-longread", "article-exhibit__header", "article-exhibit__title", "article-exhibit", "iframe-component", "article-sidebar", "article-pullquote"];
        var tagClasses = tag.classList;
        if (tagClasses && tagClasses.length > 0) {
            tagClasses.forEach(function (tagClass) {
                if (pToSkip.indexOf(tagClass) >= 0) result = true;
            });
        }
        return result;
    }
    function IsPrevoiusElementOK(tag) {
        return tag.previousElementSibling == null || !IgnoreTag(tag.previousElementSibling) && tag.previousElementSibling.innerHTML != "";
    }
    var articleBodyContainer = $("#main-well");
    if (articleBodyContainer && articleBodyContainer.length > 0) {
        var articleRightRail = $(".article-right-rail");
        if (articleRightRail && articleRightRail.length > 0) {
            var articleCTA = $('.article-call-to-action');
            if (articleCTA && articleCTA.length > 0) {
                articleRightRail.css("visibility", "visible");
            } else {
                var rightRailChildren = articleRightRail.find('.js-article-longread-right-rail-item, .advertising');
                if (rightRailChildren && rightRailChildren.length > 0) {
                    var metaTagEndHeight = isNaN($('#long-metadata')[0].offsetTop + $('#long-metadata')[0].offsetHeight) ? 0 : $('#long-metadata')[0].offsetTop + $('#long-metadata')[0].offsetHeight;
                    var pTags = articleBodyContainer.children('.root') ? articleBodyContainer.children('.root').children() : null; //articleBodyContainer.find('p, aside, div.iframe-component, ul.article-list--ul');
                    if (pTags && pTags.length > 0) {
                        var rightChildrenCount = rightRailChildren.length;
                        var processedRightChildernCount = 0;
                        var nextOffsetTop = 0;
                        var groupOfTags = document.createElement("aside");
                        groupOfTags.setAttribute("class", "inline-related-content js-group-tags");
                        var tagProcessed = false;
                        pTags.each(function (item) {
                            tagProcessed = false;
                            //If p tag doesn't sharing real estate with meta data section
                            if (($(pTags[item])[0].offsetTop > metaTagEndHeight)) {
                                if (processedRightChildernCount < rightChildrenCount) {
                                    if (!IgnoreTag(pTags[item]) && $(pTags[item])[0].offsetTop > nextOffsetTop) {
                                        if (IsPrevoiusElementOK(pTags[item])) {
                                            var element = document.createElement("aside");
                                            element.setAttribute("class", "inline-related-content");

                                            if ($(rightRailChildren[processedRightChildernCount]).hasClass('js-longread-group-tags')) {
                                                $(groupOfTags).append(rightRailChildren[processedRightChildernCount].outerHTML);
                                                element.classList.add("js-longread-tag");
                                                if (groupOfTags.childNodes.length > 1) {
                                                    element.classList.add("remove");
                                                }
                                            } else if ($(rightRailChildren[processedRightChildernCount]).hasClass('advertising')) {
                                                $(element).append(CreateHtmlForGoogleAd(rightRailChildren[processedRightChildernCount]));
                                            } else {
                                                element.innerHTML = rightRailChildren[processedRightChildernCount].outerHTML;
                                            }
                                            if (!element.classList.contains("remove") || element.innerHTML != "") {
                                                if (window.matchMedia("(max-width: 767px)").matches) {
                                                    $(element).insertAfter(pTags[item]);
                                                } else {
                                                    $(element).insertBefore(pTags[item]);
                                                }
                                            }
                                            var rightRailElementOffsetHeight = rightRailChildren[processedRightChildernCount].offsetHeight;
                                            if (rightRailChildren[processedRightChildernCount].classList.value.split(' ').indexOf('contentRecommendations') >= 0) {
                                                rightRailElementOffsetHeight = 2400;
                                            }
                                            nextOffsetTop = isNaN($(pTags[item])[0].offsetTop + rightRailElementOffsetHeight) ? 0 : $(pTags[item])[0].offsetTop + rightRailElementOffsetHeight;

                                            processedRightChildernCount++;
                                        } else {
                                            var pTagBottom = isNaN($(pTags[item])[0].offsetTop + $(pTags[item].previousElementSibling)[0].offsetHeight) ? 0 : $(pTags[item])[0].offsetTop + $(pTags[item].previousElementSibling)[0].offsetHeight;
                                            if (pTagBottom > nextOffsetTop) {
                                                tagProcessed = true;
                                                nextOffsetTop = pTagBottom;
                                            }
                                        }
                                    }
                                    else {
                                        var pTagBottom = isNaN($(pTags[item])[0].offsetTop + $(pTags[item])[0].offsetHeight) ? 0 : $(pTags[item])[0].offsetTop + $(pTags[item])[0].offsetHeight;
                                        if (pTagBottom > nextOffsetTop) {
                                            tagProcessed = processedRightChildernCount <= 0;
                                            nextOffsetTop = pTagBottom;
                                        }
                                    }
                                }

                                if (!tagProcessed && $(pTags[item])[0].offsetTop < nextOffsetTop &&
                                    IgnoreTag(pTags[item]) && pTags[item].innerHTML != "" && !window.matchMedia("(max-width: 767px)").matches) {
                                    if (!pTags[item].classList.contains("article-pullquote")) {
                                        $(pTags[item]).css('max-width', '33.33%');
                                    } else {
                                        $(pTags[item]).css('max-width', '73%');
                                    }
                                }
                            }
                        });
                        var element = document.createElement("aside");
                        element.setAttribute("class", "inline-related-content");
                        while (processedRightChildernCount < rightChildrenCount) {
                            if ($(rightRailChildren[processedRightChildernCount]).hasClass('js-longread-group-tags')) {
                                $(groupOfTags).append(rightRailChildren[processedRightChildernCount].outerHTML);
                                element.classList.add("js-longread-tag");
                                if (groupOfTags.childNodes.length > 1) {
                                    element.classList.add("remove");
                                }
                            } else if ($(rightRailChildren[processedRightChildernCount]).hasClass('advertising')) {
                                $(element).append(CreateHtmlForGoogleAd(rightRailChildren[processedRightChildernCount]));
                            } else {
                                element.append(rightRailChildren[processedRightChildernCount]);
                            }
                            processedRightChildernCount++;
                        }
                        if (!element.classList.contains("remove") || element.innerHTML != "") {
                            articleBodyContainer.first().append(element);
                        }
                        if ($(articleBodyContainer).find('.js-longread-tag').length > 0) {
                            $(articleBodyContainer).find('.js-longread-tag')[0].innerHTML = groupOfTags.innerHTML;
                        }
                        articleRightRail.hide();
                    } else {
                        articleRightRail.css("visibility", "visible");
                    }
                }
            }
        }
    }
};
