$(function () {
    var inputs = $('input[type="radio"]'),
        productValue, 
        rowID;

    $('#form-search-preferences input[type="radio"]').change(function (event) {
        $('#btnsubmit').removeAttr('disabled');
        for (var i = 0; i < inputs.length; i++) {
            if ($('input[name=SavedSearchInput' + i + ']').length === 0) {
                break;
            }
            productValue = $(event.currentTarget).val();
            rowID = $(event.currentTarget).closest('tr').attr("id").split("row")[1];

            //Set selectValue hidden field
            $('#SavedSearchInput' + rowID + 'Frequency').val(productValue);           
            return;
        }
    });
        
    $('#form-search-preferences-none input[type="radio"]').change(function (event) {
        $('#btnsubmitnone').removeAttr('disabled');
        for (var i = 0; i < inputs.length; i++) {
            if ($('input[name=SavedSearchInputN' + i + ']').length === 0) {
                break;
            }
            productValue = $(event.currentTarget).val();
            rowID = $(event.currentTarget).closest('tr').attr("id").split("row")[1];

            //Set selectValue for none hidden field           
            $('#SavedSearchInputNone' + rowID + 'FrequencyN').val(productValue);
            return;
        }
    });

    $('.saved-preferences .page-account__submit-wrapper button[type="submit"]').click(function () {
        window.location.reload(true);
    });
});