(function () {
    var $carouselInfograph = $('.carousel-infographic'),
    init = function () {
        // page check
        if (!$carouselInfograph || $carouselInfograph.length === 0) return;
        $carouselInfograph.each(function () {
            var currCIG = $(this);
            currCIG.owlCarousel({
                items: 1,
                loop: true,
                nav: true,
                lazyLoad: true,
                navText: [
                    "<svg class='sorting-arrows__arrow sorting-arrows__arrow--down right-arrow'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>",
                    "<svg class='sorting-arrows__arrow sorting-arrows__arrow--down left-arrow'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/dist/img/svg-sprite.svg#sorting-arrow-table'></use></svg>"
                ]
            });
        });
    }
  init();
})();
