/* global angular, analytics_data */

// THIRD-PARTY / VENDOR
import Zepto from "./zepto.min";
import svg4everybody from "./svg4everybody";
import Cookies from "./jscookie";
import DrapSwap from "./zepto.dragswap";
import DropBox from "./DragDropTouch";
//Spinner
import "./informaui.spinner";

// CAROUSEL
//import highlight from './carousel/highlight.pack';
import zeptoData from "./carousel/zepto.data";
//import app from './carousel/app';
import "./carousel/owl.carousel";
import "./zepto.suggest";
// CONTROLLERS
import FormController from "./controllers/form-controller";
import PopOutController from "./controllers/pop-out-controller";
import BookmarkController from "./controllers/bookmark-controller";
import ResetPasswordController from "./controllers/reset-password-controller";
import RegisterController from "./controllers/register-controller";
import SortableTableController from "./controllers/sortable-table-controller";
import LightboxModalController from "./controllers/lightbox-modal-controller";
import { analyticsEvent } from "./controllers/analytics-controller";
import tooltipController from "./controllers/tooltip-controller";

// COMPONENTS
import "./components/horizontal-menu";
import "./components/horizontal-sub-menu";
import "./components/article-sidebar-component";
import "./components/save-search-component";
import "./components/myview-settings";
import "./components/pagination";
import "./components/personalisation";
import "./components/id-comparechart";
import "./components/id-responsive-table";
import "./components/id-financial-responsive-table";
import "./components/id-quarterly-responsive-table";
import "./components/id-comparefinancialresults";
import "./components/latest-casuality";
import "./components/id-merge-acquistion";
import "./components/AMCharts-merges-acquisition";
import "./components/dynamic-content-recomendation";
import "./components/ll-casuality-listing";
import "./components/ll-casuality-detail";
import "./components/ll-market-data";
import "./components/ll-tanker-fixtures";
import "./components/ll-market-data-dryCargo";
import "./components/ll-market-data-dryCargo-bulkFixture";
import "./components/accordionStockChart";
import "./components/amGraphParam";
import "./components/table_charts";
import "./components/scrollbar";
import "./components/ll-ship-coal-export";
import "./components/ll-tanker-pure-chem-page";
import "./components/ll-marketdata-drycargo-ssyal";
import "./components/ll-cockett-bunker";
import "./components/ll-ship-roro";
import "./components/ll-ship-container-ship";
import "./components/ll-shipContainerShipFixtures";
import "./components/ll-fisDryBulk";
import "./components/ll-howeRobinson";
import "./components/free-trial";
import "./components/searchcriteria";
import "./components/bookmark-article-component";
import "./components/long-read-template-component";
import "./components/powerbi-embed-component";
import "./components/tableau-embed-component";
import "./components/service-area-component";
import "./components/ask-an-analyst";
import "./components/analytics-asset-family";
import "./components/save-search-alert";
import "./components/dropdown-download";
import "./components/contact-form";
import "./components/assetfamily-ul";
import "./components/hero-banner";
import "./components/accordian-table";
import "./components/related-seasearcher";
import "./components/tabs-content";
import "./components/latest-ilaw-data";
import "./components/externalurl-utm";
import "./components/carousel-infographic";

// TimeZone Offset Cookie
import "./components/clientBrowserTimezoneOffset";

// OTHER CODE
import NewsletterSignupController from "./newsletter-signup";
import SearchScript from "./search-page";

import { toggleIcons } from "./toggle-icons";

// Global scope to play nicely with Angular
window.toggleIcons = toggleIcons;

/* Polyfill for scripts expecting `jQuery`. Also see: CSS selectors support in zepto.min.js */
window.jQuery = $;

import selectivity from "./selectivity-full";
import "./components/mkt-form";
import video from "./components/video-mini";
import videomodal from "./modal";

$(function () {
  var hash = window.location.hash;
  // now scroll to element with that id
  $("html, body").animate({ scrollTop: $(hash).offset().top });
});

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

if (window.Intercom) {
  $("#account-sign-out").on("click", function (e) {
    e.preventDefault();
    Intercom("shutdown");
    window.location.href = $(e.target).attr("href");
  });
}

// Make sure proper elm gets the click event
// When a user submits a Forgot Password request, this will display the proper
// success message and hide the form to prevent re-sending.

var showForgotPassSuccess = function () {
  $(".pop-out__sign-in-forgot-password-nested").toggleClass("is-hidden");
  $(".pop-out__sign-in-forgot-password")
    .find(".alert-success")
    .toggleClass("is-active");
};

window.findTooltips = function () {
  $(".js-toggle-tooltip").each(function (index, item) {
    var tooltip;
    $(item).data("ttVisible", false);
    $(item).data("ttTouchTriggered", false);

    $(item).on("mouseenter touchstart", function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "touchstart") {
        $(item).data("ttTouchTriggered", true);
      }

      // Actual mouse events thrown can be any number of things...
      if (
        (e.type === "mouseover" || e.type === "mouseenter") &&
        $(item).data("ttTouchTriggered")
      ) {
        // Do nothing
      } else if ($(item).data("ttVisible") && e.type === "touchstart") {
        $(item).data("ttVisible", false);
        $(item).data("ttTouchTriggered", false);
        tooltip.hidePopup();
      } else {
        $(item).data("ttVisible", true);
        const offsets = $(item).offset();
        tooltip = tooltipController({
          isHidden: false,
          html: $(item).data("tooltip-text"),
          top: offsets.top,
          left: offsets.left + $(this).width() / 2,
          triangle: "bottom",
        });
      }
    });

    $(item).on("mouseleave", function () {
      $(item).data("ttVisible", false);
      tooltip.hidePopup();
      $(".popup").remove();
    });
  });
};

window.findTooltips();

var renderIframeComponents = function () {
  $(".iframe-component").each(function (index, elm) {
    var desktopEmbed = $(elm).find(".iframe-component__desktop");
    var mobileEmbed = $(elm).find(".iframe-component__mobile");

    var isEditMode = $(this).hasClass("is-page-editor");

    var showMobile = $(window).width() <= 480 || isEditMode;
    var showDesktop = !showMobile || isEditMode;

    if (showMobile) {
      mobileEmbed.show();
      if (mobileEmbed.html() === "")
        mobileEmbed.html(decodeHtml(mobileEmbed.data("embed-link")));
    } else {
      mobileEmbed.hide();
    }

    if (showDesktop) {
      desktopEmbed.show();
      if (desktopEmbed.html() === "")
        desktopEmbed.html(decodeHtml(desktopEmbed.data("embed-link")));
    } else {
      desktopEmbed.hide();
    }

    var desktopMediaId = $(elm)
      .find(".iframe-component__desktop")
      .data("mediaid");

    var url = window.location.href;
    url.replace("#", "");
    if (url.indexOf("?") < 0) {
      url += "?";
    } else {
      url += "&";
    }

    url += "mobilemedia=true&selectedid=" + desktopMediaId;
    $(elm)
      .find(".iframe-component__mobile a")
      .data("mediaid", url)
      .attr("href", null);
  });
};

var renderTableau = function () {
  var desktopEmbed = $(".tableau_component__desktop");
  var mobileEmbed = $(".tableau_component__mobile");

  var mobileHiddenValue = $("#IsMobileDashboardAvailable").val();
  var showMobile = $(window).width() <= 480;
  var showDesktop = !showMobile;

  if (showMobile) {
    if (mobileHiddenValue === "True") {
      mobileEmbed.show();
      desktopEmbed.hide();
    } else {
      desktopEmbed.show();
    }
  }

  if (showDesktop) {
    desktopEmbed.show();
    mobileEmbed.hide();
  }
};

var renderAMchart = function () {
  if ($("#amchartDashboard").hasClass("amchart-dashboard")) {
    var amChartType = $("#ChartType").val().toLowerCase();
    var dataProvider = $("#amChartDataProvider").val().toLowerCase();
    var graphType = $("#GraphType").val().toLowerCase();
    var categoryField = $("#CategoryField").val().toLowerCase();
    var valueField = $("#ValueField").val().toLowerCase();

    AmCharts.makeChart("chartdiv", {
      type: amChartType,
      dataProvider: chartData,
      categoryField: categoryField,
      graphs: [
        {
          valueField: valueField,
          type: graphType,
        },
      ],
      responsive: {
        enabled: true,
      },
    });
  }
};

var decodeHtml = function (html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

$(document).ready(function () {
  //AM Charts
  if ($("#amchartData") && $("#amchartData").length) {
    var amchartVal = JSON.parse($("#amchartData").val()),
      createNewObj = {},
      chart;

    if (typeof amchartVal.dataProvider !== "object") {
      for (var prop in amchartVal) {
        if (prop !== "dataProvider") {
          createNewObj[prop] = amchartVal[prop];
        } else {
          createNewObj[prop] = chartDataVal;
        }
      }
      chart = AmCharts.makeChart("chartdiv", createNewObj);
    } else {
      chart = AmCharts.makeChart("chartdiv", amchartVal);
    }
  }
  //messaging web users
  window.dismiss = function () {
    $(".dismiss").on("click", function () {
      Cookies.set("dismiss_cookie", "dismiss_cookie_created", "");
      $(".messaging_webUsers").remove();
      $(".messaging_webUsers_white").remove();

      var dismiss_data = {
        event_name: "message_dismissal",
        ga_eventCategory: "Messaging Frame",
        ga_eventAction: "Dismissal",
        ga_eventLabel: "Dismiss",
        page_name: analytics_data["page_name"],
      };

      analyticsEvent($.extend(analytics_data, dismiss_data));
    });
  };
  window.dismiss();

  //Job Listing Pagination
  if (
    $("#jobTilesCount") &&
    $("#jobTilesCount").length &&
    $("#noofJobsPerPage") &&
    $("#noofJobsPerPage").length
  ) {
    var totalCategories = $("#jobTilesCount").val(),
      categoryLimit = $("#noofJobsPerPage").val();

    window.setPagination({
      totalCategories: parseInt(totalCategories),
      categoryLimit: parseInt(categoryLimit),
      currentPage: 1,
      paginationEle: ".job_list_individual",
    });

    window.loadPaginationNums();
  }

  //Food news Pagination
  if (
    $("#foodNewsCount") &&
    $("#foodNewsCount").length &&
    $("#noofJobsPerPage") &&
    $("#noofJobsPerPage").length
  ) {
    var totalCategories1 = $("#foodNewsCount").val(),
      categoryLimit1 = $("#noofJobsPerPage").val();

    window.setPagination({
      totalCategories: parseInt(totalCategories1),
      categoryLimit: parseInt(categoryLimit1),
      currentPage: 1,
    });

    window.loadPaginationNums();
  }

  window.custom_label = function () {
    $("body")
      .off()
      .on("click", ".label-check", function (e) {
        if ($(this).hasClass("label-check")) {
          var ele = $(this).find("input");
          if (ele.is(":checked")) {
            ele.prop("checked", false);
            ele.parent("div").removeClass("wcs-c-on");
          } else {
            ele.prop("checked", true);
            ele.parent("div").addClass("wcs-c-on");
          }
        }
      });
  };
  window.custom_label();

  window.personalised_nav = function () {
    //personalise pop up
    var modal = document.getElementById("myModal");

    // Get the button that opens the modal
    var btn = document.getElementById("myBtn");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("personalise_close")[0];

    // When the user clicks the button, open the modal
    $(document).on("click", "#myBtn", function () {
      modal.style.display = "block";
    });

    // When the user clicks on <span> (x), close the modal
    if (span !== undefined) {
      span.onclick = function () {
        modal.style.display = "none";
      };
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  };
  window.personalised_nav();

  var mediaTable = getParameterByName("mobilemedia");
  if (mediaTable === "true") {
    $("table").each(function () {
      $(this).attr("style", "display:block");
    });
  }

  // Anti Forgery Token
  var requestVerificationToken = $(".main__wrapper").data(
    "request-verification-token"
  );

  var sortTheTables = new SortableTableController();

  window.lightboxController = new LightboxModalController();

  /* * *
        Traverses the DOM and registers event listeners for any pop-out triggers.
        Bound explicitly to `window` for easier access by Angular.
    * * */
  window.indexPopOuts = function () {
    window.controlPopOuts = new PopOutController(".js-pop-out-trigger");

    window.controlPopOuts.customize({
      id: "header-register",
      tabStyles: {
        deskHeight: 87,
        tabletHeight: 72,
        phoneHeight: "", // Default
      },
    });

    window.controlPopOuts.customize({
      id: "myView-header-register",
      tabStyles: {
        deskHeight: 87,
        tabletHeight: 72,
        phoneHeight: "", // Default
      },
    });
    window.controlPopOuts.customize({
      id: "header-register",
      tabStyles: {
        deskHeight: 87,
        tabletHeight: 72,
        phoneHeight: "", // Default
      },
    });

    window.controlPopOuts.customize({
      id: "header-signin",
      tabStyles: {
        deskHeight: 87,
        tabletHeight: 72,
        phoneHeight: "", // Default
      },
    });
  };

  window.indexPopOuts();

  window.bookmark = new BookmarkController();

  /* * *
        Traverses the DOM and registers event listeners for any bookmarkable
        articles. Bound explicitly to `window` for easier access by Angular.
    * * */
  window.indexBookmarks = function () {
    // Toggle bookmark icon
    $(".js-bookmark-article")
      .off("click")
      .on("click", function bookmarkArticle(e) {
        e.preventDefault();
        window.bookmark.toggle(this);
      });
  };

  window.indexBookmarks();

  //Data tool Landing page

  window.addWidth = function () {
    //landing page
    if ($(".demoText").is(":visible") && !$(".video-demo").is(":hidden")) {
      $(".demoText").addClass("add-width-100");
    }
    if (!$(".demoText").is(":hidden") && $(".video-demo").is(":visible")) {
      $(".video-demo").addClass("add-width-100");
    }
    if ($(".demoText").is(":visible") && $(".video-demo").is(":visible")) {
      $(".demoText").removeClass("add-width-100");
      $(".video-demo").removeClass("add-width-100");
    }
  };
  window.addWidth();

  /* * *
        If a user tries bookmarking an article while logged out, they'll be
        prompted to sign in first. This checks for any articles that have been
        passed along for post-sign-in bookmarking. Bound explicitly to `window`
        for easier access by Angular.
    * * */
  window.autoBookmark = function () {
    var bookmarkTheArticle = function (article) {
      $(".js-bookmark-article").each(function (indx, item) {
        if (
          $(item).data("bookmark-id") === article &&
          !$(item).data("is-bookmarked")
        ) {
          $(item).click();
        } else {
          // already bookmarked or not a match
        }
      });
    };

    var urlVars = window.location.href.split("?");
    var varsToParse = urlVars[1] ? urlVars[1].split("&") : null;
    if (varsToParse) {
      for (var i = 0; i < varsToParse.length; i++) {
        var pair = varsToParse[i].split("=");
        if (pair[0] === "immb") {
          bookmarkTheArticle(pair[1]);
        }
      }
    }
  };

  window.autoBookmark();

  var BookmarkCatche = {
    init: function (items) {
      var bookmarkIds = $("div[data-bookmark-id]"),
        allbookmarks = [];
      $.each(bookmarkIds, function (idx, item) {
        allbookmarks.push({ BookmarkId: $(item).attr("data-bookmark-id") });
      });

      $.ajax({
        url: "/Account/api/SavedDocumentApi/IsSaved/",
        type: "POST",
        data: JSON.stringify(allbookmarks),
        dataType: "json",
        contentType: "application/json",
        context: this,
        success: function (response) {
          $.each(response, function (idx, item) {
            var bookmarkedId = $(
              'div[data-bookmark-id="' + item.bookmarkId + '"]'
            );
            if (item.state) {
              bookmarkedId
                .attr("data-is-bookmarked", true)
                .attr("data-salesforce-id", item.salesforceId);
              bookmarkedId.find(".article-bookmark").removeClass("is-visible");
              bookmarkedId
                .find(".article-bookmark__bookmarked")
                .addClass("is-visible");
              bookmarkedId.find(".js-bookmark-label").html("Bookmarked");
            } else {
              bookmarkedId
                .attr("data-is-bookmarked", false)
                .attr("data-salesforce-id", "");
              bookmarkedId.find(".article-bookmark").addClass("is-visible");
              bookmarkedId
                .find(".article-bookmark__bookmarked")
                .removeClass("is-visible");
              bookmarkedId.find(".js-bookmark-label").html("Bookmark");
            }
          });
        },
        error: function (response) {
          return false;
        },
      });
    },
    checkSignedIn: function (items, cls1, cls2) {
      for (var i = 0; i < items.length; i++) {
        $(items[i]).removeClass(cls1).addClass(cls2);
        if (cls1 === "js-pop-out-trigger") {
          window.indexBookmarks();
        }
      }
    },
  };
  var Items = $(".article-preview__bookmarker");
  if (
    !$("div[data-pop-out-id='header-signin']").length &&
    $("div[data-bookmark-id]").length
  ) {
    BookmarkCatche.checkSignedIn(
      Items,
      "js-pop-out-trigger",
      "js-bookmark-article"
    );
    BookmarkCatche.init(Items);
  } else {
    BookmarkCatche.checkSignedIn(
      Items,
      "js-bookmark-article",
      "js-pop-out-trigger"
    );
    window.indexPopOuts();
  }

  /* * *
        Toggle global header search box
        (toggles at tablet/smartphone sizes, always visible at desktop size)
    * * */
  $(".js-header-search-trigger").on("click", function toggleMenuItems(e) {
    if ($(window).width() <= 800) {
      $(".header-search__wrapper").toggleClass("is-active").focus();
    } else {
      $(e.target).closest("form").submit();
    }
    e.preventDefault();
    return false;
  });

  var newsletterSignup = new NewsletterSignupController();
  newsletterSignup.checkForUserSignedUp();
  newsletterSignup.addControl(
    ".js-newsletter-signup-submit",
    null,
    function (triggerElement) {}
  );

  /* * *
        Handle user sign-in attempts.
    * * */
  var userSignIn = new FormController({
    observe: ".js-sign-in-submit",
    successCallback: function (form, context, event) {
      var loginRegisterMethod = "login_register_component";
      if ($(form).parents(".pop-out__sign-in").length > 0)
        loginRegisterMethod = "global_login";

      var loginAnalytics = {
        event_name: "login",
        login_state: "successful",
        userName: '"' + $(form).find("input[name=username]").val() + '"',
        login_register_method: loginRegisterMethod,
      };

      analyticsEvent($.extend(analytics_data, loginAnalytics));

      /* * *
                If `pass-article-id` is set, user is probably trying to sign in
                after attempting to bookmark an article. Add the article ID to
                the URL so `autoBookmark()` catches it.
            * * */
      var passArticleId = $(form)
        .find(".sign-in__submit")
        .data("pass-article-id");
      if (passArticleId) {
        var sep = window.location.href.indexOf("?") > -1 ? "&" : "?";

        window.location.href =
          window.location.href + sep + "immb=" + passArticleId;

        // If Angular, need location.reload to force page refresh
        if (typeof angular !== "undefined") {
          angular.element($(".search-results")[0]).controller().forceRefresh();
        }
      } else {
        window.location.reload(false);
      }
    },
    failureCallback: function (form, context, event) {
      var loginAnalytics = {
        event_name: "Login Failure",
        login_state: "unsuccessful",
        userName: '"' + $(form).find("input[name=username]").val() + '"',
      };

      analyticsEvent($.extend(analytics_data, loginAnalytics));
    },
  });

  var resetPassword = new FormController({
    observe: ".form-reset-password",
    successCallback: function () {
      $(".form-reset-password").find(".alert-success").show();
      var isPassword = $(".form-reset-password").data("is-password");
      if (isPassword) {
        analyticsEvent(
          $.extend(analytics_data, { event_name: "password reset success" })
        );
      }
    },
    failureCallback: function () {
      var isPassword = $(".form-reset-password").data("is-password");
      if (isPassword) {
        analyticsEvent(
          $.extend(analytics_data, { event_name: "password reset failure" })
        );
      }
    },
  });

  var newResetPassToken = new FormController({
    observe: ".form-new-reset-pass-token",
    successCallback: function () {
      $(".form-new-reset-pass-token").find(".alert-success").show();
      analyticsEvent(
        $.extend(analytics_data, { event_name: "password reset success" })
      );
    },
    failureCallback: function () {
      analyticsEvent(
        $.extend(analytics_data, { event_name: "password reset failure" })
      );
    },
  });

  $(".js-corporate-master-toggle").on("change", function () {
    if ($(this).prop("checked")) {
      $(".js-registration-corporate-wrapper").show();
    } else {
      $(".js-registration-corporate-wrapper").hide();
    }
  });

  var userRegistrationController = new FormController({
    observe: ".form-registration",
    successCallback: function (form, context, event) {
      // Stash registration type so next page can know it without
      // an additional Salesforce call
      Cookies.set("registrationType", context.response.registration_type, {});

      analyticsEvent(
        $.extend(analytics_data, {
          registration_type: context.response.registration_type,
        })
      );
    },
    failureCallback: function (form, response) {
      var errorMsg = $(".page-registration__error").text();
      if (response.reasons && response.reasons.length > 0) {
        errorMsg = "[";
        for (var reason in response.reasons) {
          errorMsg += response.reasons[reason] + ",";
        }
        errorMsg = errorMsg.substring(0, errorMsg.length - 1);
        errorMsg += "]";
      }
      analyticsEvent(
        $.extend(analytics_data, {
          event_name: "registration failure",
          registraion_errors: errorMsg,
        })
      );
    },
  });

  var userRegistrationFinalController = new FormController({
    observe: ".form-registration-optins",
    successCallback: function (form, context, event) {
      var registrationType = Cookies.get("registrationType");

      analyticsEvent(
        $.extend(analytics_data, {
          registration_type: registrationType,
        })
      );

      Cookies.remove("registrationType");
    },
    failureCallback: function (form, response) {
      var errorMsg = $(".page-registration__error").text();
      if (response.reasons && response.reasons.length > 0) {
        errorMsg = "[";
        for (var reason in response.reasons) {
          errorMsg += response.reasons[reason] + ",";
        }
        errorMsg = errorMsg.substring(0, errorMsg.length - 1);
        errorMsg += "]";
      }
      analyticsEvent(
        $.extend(analytics_data, {
          event_name: "registration failure",
          registraion_errors: errorMsg,
        })
      );
    },
  });

  var userRegistrationFinalv2Controller = new FormController({
    observe: ".form-registration-optins-v2",
    successCallback: function (form, context, event) {
      var registrationType = Cookies.get("registrationType");

      analyticsEvent(
        $.extend(analytics_data, {
          registration_type: registrationType,
        })
      );

      Cookies.remove("registrationType");
    },
    failureCallback: function (form, response) {
      var errorMsg = $(".page-registration__error").text();
      if (response.reasons && response.reasons.length > 0) {
        errorMsg = "[";
        for (var reason in response.reasons) {
          errorMsg += response.reasons[reason] + ",";
        }
        errorMsg = errorMsg.substring(0, errorMsg.length - 1);
        errorMsg += "]";
      }
      analyticsEvent(
        $.extend(analytics_data, {
          event_name: "registration failure",
          registraion_errors: errorMsg,
        })
      );
    },
  });

  var userPreRegistrationController = new FormController({
    observe: ".form-pre-registration",
    successCallback: function (form) {
      var usernameInput = $(form).find(".js-register-username");

      var forwardingURL = $(form).data("forwarding-url");
      var sep = forwardingURL.indexOf("?") < 0 ? "?" : "&";
      var nextStepUrl =
        $(form).data("forwarding-url") +
        sep +
        usernameInput.attr("name") +
        "=" +
        encodeURIComponent(usernameInput.val());

      var loginRegisterMethod = "global_registration";
      if ($(form).hasClass("user-calltoaction"))
        loginRegisterMethod = "login_register_component";

      analyticsEvent(
        $.extend(analytics_data, {
          event_name: "registration",
          login_register_method: loginRegisterMethod,
        })
      );

      window.location.href = nextStepUrl;
    },
  });

  var askTheAnalystController = new FormController({
    observe: ".form-ask-the-analyst",
    successCallback: function (form) {
      $(".js-ask-the-analyst-form-wrapper").hide();
      $(".js-ask-the-analyst-recip-success").html(
        $(".js-ask-the-analyst-recip-addr").val()
      );
      $(".js-ask-the-analyst-success").show();

      // Reset the Ask The Analyst pop-out to its default state when closed
      $(".js-dismiss-ask-the-analyst").one("click", function () {
        $(".js-ask-the-analyst-form-wrapper").show();
        $(".js-ask-the-analyst-success").hide();
      });
    },
  });

  $(".click-logout").on("click", function (e) {
    analyticsEvent($.extend(analytics_data, { event_name: "logout" }));
  });

  //Specific to Sign Out Vertical Login
  $(".vertical__sign-out").on("click", function (e) {
    e.preventDefault();
    $("#hiddenforms_logout form").each(function () {
      $(this)[0].submit();
    });
    var Url = $(this).attr("href");
    window.location.href = Url;
  });

  var emailAuthorController = new FormController({
    observe: ".form-email-author",
    successCallback: function (form) {
      $(".js-email-author-form-wrapper").hide();
      $(".js-email-author-recip-success").html(
        $(".js-email-author-recip-addr").val()
      );
      $(".js-email-author-success").show();

      // Reset the Email Author pop-out to its default state when closed
      $(".js-dismiss-email-author").one("click", function () {
        $(".js-email-author-form-wrapper").show();
        $(".js-email-author-success").hide();
      });
    },
  });

  var emailArticleController = new FormController({
    observe: ".form-email-article",
    successCallback: function (form) {
      $(".js-email-article-form-wrapper").hide();
      $(".js-email-article-recip-success").html(
        $(".js-email-article-recip-addr").val().split(";").join("; ")
      );
      $(".js-email-article-success").show();

      // Reset the Email Article pop-out to its default state when closed
      $(".js-dismiss-email-article").one("click", function () {
        $(".js-email-article-form-wrapper").show();
        $(".js-email-article-success").hide();
      });
    },
  });

  var emailSearchController = new FormController({
    observe: ".form-email-search",
    successCallback: function (form) {
      $(".js-email-search-form-wrapper").hide();
      $(".js-email-search-recip-success").html(
        $(".js-email-search-recip-addr").val()
      );
      $(".js-email-search-success").show();
      $(
        ".js-email-search-form-wrapper input, .js-email-search-form-wrapper textarea"
      ).val("");

      // Reset the Email Article pop-out to its default state when closed
      $(".js-dismiss-email-search").one("click", function () {
        $(".js-email-search-form-wrapper").show();
        $(".js-email-search-success").hide();
      });

      var event_data = {
        event_name: "toolbar_use",
        toolbar_tool: "email",
      };

      analyticsEvent($.extend(analytics_data, event_data));
    },
    beforeRequest: function () {
      var resultIDs = null;

      $(".js-search-results-id").each(function (indx, item) {
        resultIDs = resultIDs
          ? resultIDs + "," + $(item).data("bookmark-id")
          : $(item).data("bookmark-id");
      });

      $(".js-email-search-results-ids").val(resultIDs);
      $(".js-email-search-query").val($(".search-bar__field").val());
      $(".js-email-search-query-url").val(document.location.href);
    },
  });

  var accountEmailPreferencesv2Controller = new FormController({
    observe: ".form-email-preferences-v2",
    successCallback: function (form, context, event) {
      var event_data = {};
      var optingIn = null;
      var optingOut = null;

      if ($("#DoNotSendOffersOptIn").prop("checked")) {
        event_data.event_name = "email_preferences_opt_out";
      } else {
        event_data.event_name = "email_preferences_update";

        $(".js-account-email-checkbox").each(function (index, item) {
          var name = $($(this).parents("tr").find("td")[0]).text().trim();
          var type = this.name.indexOf("Daily") > 0 ? "Daily" : "Weekly";

          var combination = name + " " + type;

          if (this.checked) {
            optingIn = optingIn ? optingIn + "|" + combination : combination;
          } else {
            optingOut = optingOut ? optingOut + "|" + combination : combination;
          }
        });

        event_data.email_preferences_optin = optingIn;
        event_data.email_preferences_optout = optingOut;
      }
      debugger;
      analyticsEvent($.extend(analytics_data, event_data));
    },
  });

  var accountEmailPreferencesController = new FormController({
    observe: ".form-email-preferences",
    successCallback: function (form, context, event) {
      var event_data = {};
      var optingIn = null;
      var optingOut = null;

      if ($("#DoNotSendOffersOptIn").prop("checked")) {
        event_data.event_name = "email_preferences_opt_out";
      } else {
        event_data.event_name = "email_preferences_update";

        $(".js-account-email-checkbox").each(function (index, item) {
          var name = $($(this).parents("tr").find("td")[0]).text().trim();
          var type = this.name.indexOf("Daily") > 0 ? "Daily" : "Weekly";

          var combination = name + " " + type;

          if (this.checked) {
            optingIn = optingIn ? optingIn + "|" + combination : combination;
          } else {
            optingOut = optingOut ? optingOut + "|" + combination : combination;
          }
        });

        event_data.email_preferences_optin = optingIn;
        event_data.email_preferences_optout = optingOut;
      }
      debugger;
      analyticsEvent($.extend(analytics_data, event_data));
    },
  });

  var accountProductAlertPreferencesController = new FormController({
    observe: ".form-product-preferences",
    successCallback: function (form, context, event) {
      var event_data = {};
      var optingIn = null;
      var optingOut = null;

      if ($("#DoNotSendOffersOptIn").prop("checked")) {
        event_data.event_name = "product_preferences_opt_out";
      } else {
        event_data.event_name = "product_preferences_update";

        event_data.email_preferences_optin = optingIn;
        event_data.email_preferences_optout = optingOut;
      }
      analyticsEvent($.extend(analytics_data, event_data));
    },
  });

  var accountUpdatePassController = new FormController({
    observe: ".form-update-account-pass",
    successCallback: function (form, context, evt) {
      $(form)
        .find("input, select, textarea")
        .each(function () {
          $(this).val("");
        });
    },
  });

  var accountUpdateContactController = new FormController({
    observe: ".form-update-account-contact",
    successCallback: function (form, context, evt) {
      $(window).scrollTop(
        $(evt.target).closest("form").find(".js-form-error-general").offset()
          .top - 32
      );
    },
  });

  var savedDocumentsController = new FormController({
    observe: ".form-remove-saved-document",
    successCallback: function (form, context, evt) {
      $(evt.target).closest("tr").remove();
      if ($(".js-sortable-table tbody")[0].rows.length === 0) {
        $(".js-sortable-table").remove();
        $(".js-no-articles").show();
      }

      var event_data = {
        event_name: "bookmark_removal",
        bookmark_title: $(form).data("analytics-title"),
        bookmark_publication: $(form).data("analytics-publication"),
      };

      analyticsEvent($.extend(analytics_data, event_data));
    },
  });

  svg4everybody();

  /* * *
        MAIN SITE MENU
    * * */
  (function MenuController() {
    var getHeaderEdge = function () {
      return (
        $(".header__wrapper").offset().top + $(".header__wrapper").height()
      );
    };

    var addFixedMenu = function () {
      if ($(window).width() > 1024) {
        $(".main-menu").addClass("fixed");
        $(".main__wrapper").css("margin-left", "330px");
      }
    };
    var removeFixedMenu = function () {
      if ($(window).width() > 1024) {
        $(".main-menu").removeClass("fixed");
        $(".main__wrapper").css("margin-left", "0");
        if ($(window).scrollTop() > getHeaderEdge()) {
          $(".main-menu").addClass("fixed");
          $(".main__wrapper").css("margin-left", "330px");
        }
      }
    };

    var showMenu = function () {
      if ($(window).width() > 1024) {
        $(".main-menu-section-list,.main-menu-list").css("display", "block");
      }
      $(".main-menu").addClass("is-active");
      $(".menu-toggler").addClass("is-active");
      $(".header__wrapper .menu-toggler").addClass("is-sticky");
      $("body").addClass("is-frozen");
      addFixedMenu();
    };

    var hideMenu = function () {
      if ($(window).width() > 1024) {
        $(".main-menu-section-list,.main-menu-list").css("display", "none");
        if ($(".main-menu").height() >= $(".main__wrapper").height()) {
          var newheight = $(".main__wrapper").height() + $("footer").height();
          $(".menu-toggler").height(newheight);
        }
      }
      $(".main-menu").removeClass("is-active");
      $(".menu-toggler").removeClass("is-active");
      $("body").removeClass("is-frozen");
      if ($(window).scrollTop() <= getHeaderEdge()) {
        $(".header__wrapper .menu-toggler").removeClass("is-sticky");
      }
      removeFixedMenu();
      $(".main-menu").removeClass("shift-main-content");
      $(".js-pop-out__myViewregister").removeClass("is-active");
      $("body").removeClass("shift-content");
    };

    /* Toggle menu visibility */
    $(".js-menu-toggle-button").on("click", function toggleMenu(e) {
      $(".main-menu").hasClass("is-active") ? hideMenu() : showMenu();
      e.preventDefault();
      e.stopPropagation();
    });

    /*  If the menu is closed, let any clicks on the menu element open
    the menu. This includes the border—visible when the menu is closed—
    so it's easier to open. */
    $(".js-full-menu-toggle").on("click", function toggleMenu() {
      if (!$("body").hasClass("horizontal-menu")) {
        $(".main-menu").hasClass("is-active") ? null : showMenu();
      }
    });

    /* Attach / detach sticky menu */
    $(window).on("scroll", function windowScrolled() {
      // Only stick if the header (including toggler) isn't visible
      if (
        $(window).scrollTop() > getHeaderEdge() ||
        $(".main-menu").hasClass("is-active")
      ) {
        $(".header__wrapper .menu-toggler").addClass("is-sticky");
        addFixedMenu();
      } else {
        $(".header__wrapper .menu-toggler").removeClass("is-sticky");
        removeFixedMenu();
      }
      if (
        $("body").hasClass("horizontal-menu") &&
        !window.mobileAndTabletCheck()
      )
        window.controlPopOuts.closePopOuts();
    });
    $(window).on("resize", function () {
      if ($(window).width() < 1024) {
        $(".main-menu").removeClass("fixed");
        $(".main__wrapper").removeAttr("style");
        $(".menu-toggler").height("auto");
        $(".main-menu-section-list,.main-menu-list").css("display", "block");
      }
    });

    /* Toggle menu categories */
    $(document).on(
      "click",
      ".js-toggle-menu-section",
      function toggleMenuItems(e) {
        e.target !== this ? this.click() : $(e.target).toggleClass("is-active");
      }
    );
  })();

  $(".show-demo").click(function () {
    $(this).closest(".js-toggle-demo").toggleClass("collapsed");
    //IPMP-616
    if ($(this).parent().hasClass("collapsed")) {
      sessionStorage.setItem("mykey", "false");
      $(".sd").show();
      $(".hd").hide();
      $(".toggle-demo").hide();
    } else {
      sessionStorage.setItem("mykey", "true");
      $(".hd").show();
      $(".sd").hide();
      $(".toggle-demo").show();
    }
  });
  var persistedval = sessionStorage.getItem("mykey");
  if (persistedval === "false") {
    $(".sd").show();
    $(".hd").hide();
    $(".toggle-demo").hide();
    $(".js-toggle-demo").addClass("collapsed");
  } else {
    $(".hd").show();
    $(".sd").hide();
    $(".toggle-demo").show();
  }

  /* * *
        When a banner is dismissed, the banner ID is stored in the
        `dismissedBanners` cookie as a JSON object. Banners are invisible by default,
        so on page load, this checks if a banner on the page is dismissed or not,
        then makes the banner visible if not dismissed.
    * * */

  /* dismissed cookie banner */
  var dismissedCookieBanners = Cookies.getJSON("dismissedCookieBanners") || {};

  $(".cookie-banner").each(function () {
    if ($(this).data("banner-id") in dismissedCookieBanners === false) {
      $(this).addClass("is-visible");
    }
  });

  /* * *
    Generic banner dismiss
* * */
  $(".js-dismiss-cookie-banner").on("click", function dismissBanner(e) {
    var thisBanner = $(e.target).parents(".cookie-banner");
    thisBanner.removeClass("is-visible");

    var dismissedCookieBanners =
      Cookies.getJSON("dismissedCookieBanners") || {};
    dismissedCookieBanners[thisBanner.data("banner-id")] = true;

    // if banner has a 'dismiss-all-subdomains' attribute = true, set the domain of the cookie
    // to the top-level domain.
    var domain = document.location.hostname;
    if (thisBanner.data("dismiss-all-subdomains")) {
      var parts = domain.split(".");
      parts.shift();
      domain = parts.join(".");
    }
    Cookies.set("dismissedCookieBanners", dismissedCookieBanners, {
      expires: 3650,
      domain: domain,
    });
  });

  /* dismissed banner */

  var dismissedBanners = Cookies.getJSON("dismissedBanners") || {};
  $(".banner").each(function () {
    if ($(this).data("banner-id") in dismissedBanners === false) {
      $(this).addClass("is-visible");
    }
  });

  /* * *
        Generic banner dismiss
    * * */
  $(".js-dismiss-banner").on("click", function dismissBanner(e) {
    var thisBanner = $(e.target).parents(".banner");
    thisBanner.removeClass("is-visible");

    var dismissedBanners = Cookies.getJSON("dismissedBanners") || {};
    dismissedBanners[thisBanner.data("banner-id")] = true;

    // if banner has a 'dismiss-all-subdomains' attribute = true, set the domain of the cookie
    // to the top-level domain.
    var domain = document.location.hostname;
    if (thisBanner.data("dismiss-all-subdomains")) {
      var parts = domain.split(".");
      parts.shift();
      domain = parts.join(".");
    }
    Cookies.set("dismissedBanners", dismissedBanners, {
      expires: 3650,
      domain: domain,
    });
  });

  // For each article table, clone and append "view full table" markup
  var $tableviewfull = $(
    ".article-body-content table, .longread-article .lra-body .content table"
  );
  if ($tableviewfull && $tableviewfull.length) {
    $tableviewfull.not(".article-table--mobile-link").forEach(function (e) {
      var mediaId = $(e).data("mediaid");
      var tableLink = $(".js-mobile-table-template .article-table").clone();

      var url = window.location.href;
      url.replace("#", "");
      if (url.indexOf("?") < 0) url += "?";
      else url += "&";

      url += "mobilemedia=true&selectedid=" + mediaId;

      // $(tableLink).find('a').attr("href", url);
      $(tableLink).find("a").data("table-url", url).attr("href", null);
      $(e).after(tableLink);
    });
  }

  // Find duplicate embeds on article page
  // IITS2-312
  $("[class^=ewf-desktop-iframe] ~ [class^=ewf-mobile-iframe]").each(function (
    index,
    item
  ) {
    $(item).remove();
  });

  // When DOM loads, render the appropriate iFrame components
  // Also add a listener for winder resize, render appropriate containers
  renderIframeComponents();
  renderTableau();
  renderAMchart();

  $(window).on("resize", (event) => {
    renderIframeComponents();
    renderTableau();
  });

  // Topic links
  var topicAnchors = $(".js-topic-anchor");

  if ($(".sub-topic-links") && $(".sub-topic-links").length) {
    $(".sub-topic-links").forEach(function (e) {
      var linkList = $(e).find(".bar-separated-link-list");

      topicAnchors.forEach(function (tc) {
        var id = tc.id;
        var text = $(tc).data("topic-link-text");
        var utagInfo =
          '{"event_name"="topic-jump-to-link-click","topic-name"="' +
          text +
          '"}';
        linkList.append(
          '<a href="#' +
            id +
            '" class="click-utag" data-info=' +
            text +
            ">" +
            text +
            "</a>"
        );
      });
    });
  }

  // Display the Forgot Password block when "forgot your password" is clicked
  $(".js-show-forgot-password").on("click", function toggleForgotPass() {
    $(".js-reset-password-container").toggleClass("is-active");
  });

  // Global dismiss button for pop-outs
  $(".dismiss-button").on("click", function (e) {
    if (e.target !== this) {
      this.click();
      return;
    }
    window.controlPopOuts.closePopOuts();
  });

  // Make sure all external links open in a new window/tab
  $("a[href^=http]").each(function () {
    if (this.href.indexOf(location.hostname) === -1) {
      $(this).attr({
        target: "_blank",
      });
    }
  });

  // Send analytics for powerbi, iframe and tableau
  var powerbiAnalytics = {
    powerbi: $("div.js-powerbi-embed").length > 0 ? true : false,
  };
  analyticsEvent($.extend(analytics_data, powerbiAnalytics));

  var iframeAnalytics = {
    iframe: $("div.rte-video-container").length > 0 ? true : false,
  };
  analyticsEvent($.extend(analytics_data, iframeAnalytics));

  var tableauAnalytics = {
    tableau:
      $("div.tableau-app").length > 0 || $("div#tableau-app").length > 0
        ? true
        : false,
  };
  analyticsEvent($.extend(analytics_data, tableauAnalytics));

  // Adds analytics for article page clicks
  $(".root")
    .find("a")
    .each(function (index, item) {
      $(this).addClass("click-utag");

      var linkString;

      if (this.href.indexOf(location.hostname) === -1) {
        linkString = "External:" + this.href;
      } else {
        linkString = this.href;
      }

      //if($(window).width() >= 1024){
      //  $('.main-menu').addClass('is-active');
      //  $('.menu-toggler').addClass('is-active');
      //  $('.header__wrapper .menu-toggler').addClass('is-sticky');
      //  $('body').addClass('is-frozen');
      //}

      if ($(this).data("info") === undefined) {
        $(this).data(
          "info",
          '{ "event_name": "embeded_link_click_through", "click_through_source": "' +
            $("h1").text() +
            '", "click_through_destination": "' +
            linkString +
            '"}'
        );
      }
    });

  $(".general-header__navigation").each(function () {
    $(this).on("scroll", function () {
      var scrollLeft = $(this).scrollLeft();
      var scrollWidth = $(this)[0].scrollWidth;
      var winWidth = $(window).width() - 70;

      if (scrollLeft > 32) {
        $(".general-header__navigation-scroller--left").addClass("is-visible");
      } else {
        $(".general-header__navigation-scroller--left").removeClass(
          "is-visible"
        );
      }

      if (scrollLeft + winWidth < scrollWidth - 32) {
        $(".general-header__navigation-scroller--right").addClass("is-visible");
      } else {
        $(".general-header__navigation-scroller--right").removeClass(
          "is-visible"
        );
      }
    });

    var scrollLeft = $(this).scrollLeft();
    var scrollWidth = $(this)[0].scrollWidth;
    var winWidth = $(window).width() - 70;

    if (scrollLeft + winWidth < scrollWidth - 32) {
      $(".general-header__navigation-scroller--right").addClass("is-visible");
    } else {
      $(".general-header__navigation-scroller--right").removeClass(
        "is-visible"
      );
    }
  });

  // Smooth, clickable scrolling for General page headers
  var smoothScrollingNav = function () {
    // Cache for less DOM checking
    var Scrollable = $(".general-header__navigation");
    var Container = $(".general-header");

    // Find current scroll distance is from left and right edges
    var scrollDistance = function () {
      return {
        left: Scrollable.scrollLeft(),
        right:
          Scrollable[0].scrollWidth -
          (Container.width() + Scrollable.scrollLeft()),
      };
    };

    // Read cookie
    var readData = function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    };

    var init = function () {
      $(".general-header__navigation-scroller--right").on("click", function () {
        if (scrollDistance().right > 0) {
          // Not on right edge
          smoothScroll(200, "right");
        }
      });

      $(".general-header__navigation-scroller--left").on("click", function () {
        if (scrollDistance().left > 0) {
          smoothScroll(200, "left");
        }
      });

      // menu manipulation
      if (!$("body").hasClass("horizontal-menu")) {
        if ($(window).width() > 1024) {
          var mainMenuListItems = $(
            "ul.main-menu__section, dl.main-menu__footer"
          );
          mainMenuListItems.remove();
          $(".main-menu").append("<div class='main-menu-list'></div>");
          mainMenuListItems.each(function () {
            $(".main-menu-list").append($(this));
          });
          if ($(".main-menu__section").length > 0) {
            var mainMenuList = $("div.main-menu__section, .main-menu-list");
            mainMenuList.remove();
            $(".main-menu").append(
              "<div class='main-menu-section-list'></div>"
            );
            mainMenuList.each(function () {
              $(".main-menu-section-list").append($(this));
            });
            $(".main-menu-section-list").css({
              height: "100%",
              "overflow-y": "scroll",
            });
          } else {
            $(".main-menu-list").css({
              height: "100%",
              "overflow-y": "scroll",
            });
          }
          setTimeout(function () {
            $(".main-menu-section-list,.main-menu-list").css("display", "none");
            if (
              readData("menunavigationcookie") === "yes" &&
              window.location.pathname === "/"
            ) {
              $(".main-menu-section-list,.main-menu-list").css(
                "display",
                "block"
              );
            }
            if ($(".main-menu").height() >= $(".main__wrapper").height()) {
              var newheight =
                $(".main__wrapper").height() + $("footer").height();
              $(".menu-toggler").height(newheight);
            }
          }, 500);
        }
      }
    };

    var scrollToTimerCache;
    var totalTravel = null;
    var durationStart = null;

    // Quadratic ease-out algorithm
    var easing = function (time, distance) {
      return distance * (time * (2 - time));
    };

    var smoothScroll = function (duration, direction) {
      if (duration <= 0) {
        // Reset everything when duration time finishes
        totalTravel = null;
        durationStart = null;
        return;
      }

      // Store duration as durationStart on first loop
      durationStart = !durationStart ? duration : durationStart;

      // Store travel distance (container width) as totalTravel on first loop
      totalTravel = !totalTravel ? Container.width() : totalTravel;

      // Finds percentage of elapsed time since start
      var travelPcent = 1 - duration / durationStart;

      // Finds travel change on this loop, adjusted for ease-out
      var travel = easing(travelPcent, (totalTravel / durationStart) * 10);

      scrollToTimerCache = setTimeout(
        function () {
          if (!isNaN(parseInt(travel, 10))) {
            if (direction === "right") {
              Scrollable.scrollLeft(Scrollable.scrollLeft() + travel);
              smoothScroll(duration - 10, direction);
            } else if (direction === "left") {
              Scrollable.scrollLeft(Scrollable.scrollLeft() - travel);
              smoothScroll(duration - 10, direction);
            }
          }
        }.bind(this),
        10
      );
    };

    // Bind event listeners
    init();
  };

  $(".js-register-final").on("click", function (e) {
    var pub_newsletter = "";
    if (window.matchMedia("(max-width: 600px)").matches) {
      for (
        var i = 0;
        i < $(".mobile .newsletter_checkbox.wcs-c-on").length;
        i++
      ) {
        pub_newsletter +=
          $($(".mobile .newsletter_checkbox.wcs-c-on")[i])
            .parents(".newsletter_box")
            .find(".article-preview__headline")
            .html() + ", ";
      }
    } else {
      for (var j = 0; j < $(".newsletter_checkbox.wcs-c-on").length; j++) {
        pub_newsletter +=
          $($(".newsletter_checkbox.wcs-c-on")[j]).prev().html() + ", ";
      }
    }
    var eventDetails = {
      event_name: "newsletter-signup",
      page_name: "Newsletter",
      ga_eventCategory: "Newsletter",
      ga_eventLabel: analytics_data["publication"],
      publication_newsletter: pub_newsletter,
      user_news_email: analytics_data["user_email"],
    };
    var chkDetails = {};
    if ($("#newsletters").is(":checked")) {
      chkDetails.newsletter_optin = "true";

      eventDetails.newsletter_signup_state = "success";
      eventDetails.ga_eventAction = "Sign Up Success";

      $.extend(eventDetails, chkDetails);
      analyticsEvent($.extend(analytics_data, eventDetails));
    } else {
      chkDetails.newsletter_optin = "false";

      eventDetails.newsletter_signup_state = "unsuccessful";
      eventDetails.ga_eventAction = "Sign Up Failure";

      $.extend(eventDetails, chkDetails);
      analyticsEvent($.extend(analytics_data, eventDetails));
    }
  });

  // TODO - Refactor this code, update class name to a `js-` name
  $(".manage-preferences").click(function (e) {
    var preferencesData = {
      event_name: "manage-preferences",
    };
    if (
      $("#NewsletterOptIn").is(":checked") &&
      $("#DoNotSendOffersOptIn").is(":checked")
    ) {
      preferencesData = {
        newsletter_optin: "true",
        donot_send_offers_optin: "true",
      };
    }
    if (
      !$("#NewsletterOptIn").is(":checked") &&
      $("#DoNotSendOffersOptIn").is(":checked")
    ) {
      preferencesData = {
        newsletter_optin: "false",
        donot_send_offers_optin: "true",
      };
    }
    if (
      $("#NewsletterOptIn").is(":checked") &&
      !$("#DoNotSendOffersOptIn").is(":checked")
    ) {
      preferencesData = {
        newsletter_optin: "true",
        donot_send_offers_optin: "false",
      };
    }
    if (
      !$("#NewsletterOptIn").is(":checked") &&
      !$("#DoNotSendOffersOptIn").is(":checked")
    ) {
      preferencesData = {
        newsletter_optin: "false",
        donot_send_offers_optin: "false",
      };
    }

    analyticsEvent($.extend(analytics_data, preferencesData));
  });

  // Execute!
  smoothScrollingNav();

  // Toggle global Informa bar
  $(".informa-ribbon__title").on("click", function (e) {
    $(".informa-ribbon").toggleClass("show");
  });

  $(".js-toggle-list").on("click", function (e) {
    $(e.target).closest(".js-togglable-list-wrapper").toggleClass("is-active");
  });

  $(".click-utag").click(function (e) {
    var dataInfo = $(this).data("info");
    if ($(this).hasClass("legacy_data_info_ignore")) {
      //rely on the data properties instead of data-info
      dataInfo = null;
    }
    if (!dataInfo) {
      dataInfo = $(this).data();
    }
    analyticsEvent($.extend(analytics_data, dataInfo));
  });

  $(".search-results, .ContentRecomm-ReadNext, .suggested-article").on(
    "click",
    ".click-utag",
    function (e) {
      analyticsEvent($.extend(analytics_data, $(this).data("info")));
    }
  );

  $(".download-dropdown").on("change", function (event) {
    var value = $(this).find("select"),
      event_data,
      asset_family_type = "";

    if (value.val().includes("-pdf")) {
      asset_family_type = "pdf";
    } else if (value.val().includes("-word")) {
      asset_family_type = "word";
    } else {
      asset_family_type = asset_family_type;
    }

    event_data = {
      event_name: "download_asset_family",
      asset_family_name: $(".omd-banner h1").text(),
      asset_family_type: asset_family_type,
    };
    analyticsEvent($.extend(analytics_data, event_data));
  });

  $("#chkASBilling").click(function (e) {
    if ($(this).is(":checked")) {
      $("#ddlShippingCountry").val($("#ddlBillingCountry").val());
      $("#txtShippingAddress1").val($("#txtBillingAddress1").val());
      $("#txtShippingAddress2").val($("#txtBillingAddress2").val());
      $("#txtShippingCity").val($("#txtBillingCity").val());
      $("#txtShippingState").val($("#txtBillingState").val());
      $("#txtShippingPostalCode").val($("#txtBillingPostalCode").val());
    }
  });

  // Account - Email Preferences toggler
  $(".js-account-email-toggle-all").on("click", function (e) {
    $(".js-update-email-prefs").attr("disabled", null);
  });

  $(".js-account-email-checkbox").on("click", function (e) {
    $(".js-update-email-prefs").attr("disabled", null);
  });

  // Twitter sharing JS
  window.twttr = (function (t, e, r) {
    var n,
      i = t.getElementsByTagName(e)[0],
      w = window.twttr || {};
    return t.getElementById(r)
      ? w
      : ((n = t.createElement(e)),
        (n.id = r),
        (n.src = "https://platform.twitter.com/widgets.js"),
        i.parentNode.insertBefore(n, i),
        (w._e = []),
        (w.ready = function (t) {
          w._e.push(t);
        }),
        w);
  })(document, "script", "twitter-wjs");

  // Pretty select boxes
  if ($("select:not(.ng-scope)").length > 0) {
    $("select:not(.ng-scope)").selectivity({
      showSearchInputInDropdown: false,
      positionDropdown: function ($dropdownEl, $selectEl) {
        $dropdownEl.css("width", $selectEl.width() + "px");
      },
    });
  }

  $(".selectivity-input .selectivity-single-select").each(function () {
    $(this).append(
      '<span class="selectivity-arrow"><svg class="alert__icon"><use xlink:href="/dist/img/svg-sprite.svg#sort-down-arrow"></use></svg></span>'
    );
  });

  //IPMP-1760
  if ($(".article-body-content aside:first").hasClass("article-inline-image")) {
    $(".package-control-articles__quarter").insertAfter(
      ".article-body-content .article-inline-image"
    );
  } else {
    $(".package-control-articles__quarter").insertBefore(
      ".article-body-content .root"
    );
  }

  //IPMP-2220
  if ($(".myView-placeholder").length === 0) {
    $(".header-account-right-access").addClass("nomyView");
    $(".header-publication-links").addClass("nomyView");
  }

  //Update state Field on Contact Page
  if ($(".page-account-contact").length > 0) {
    var stateValue = $("#hiddenState").val(),
      Value = $("#ddlShippingCountry")
        .find(".selectivity-single-selected-item")
        .attr("data-item-id");

    $.ajax({
      url: "/Account/api/ContactInfoApi/GetStates/",
      data: { Country: Value },
      type: "POST",
      success: function success(Data) {
        $("#ddlShippingState").remove();
        $('label[for="ShipState"]')
          .parent()
          .append('<select name="ShipState" id="ddlShippingState"></select>');

        var stateValue = $("#hiddenState").val();

        for (var key in Data) {
          if (stateValue.length > 0 && stateValue === Data[key].ID) {
            $("#ddlShippingState").append(
              '<option selected value="' +
                Data[key].ID +
                '">' +
                Data[key].Name +
                "</option>"
            );
          } else {
            $("#ddlShippingState").append(
              '<option value="' +
                Data[key].ID +
                '">' +
                Data[key].Name +
                "</option>"
            );
          }
        }
        $("#ddlShippingState").selectivity({
          showSearchInputInDropdown: false,
        });
        $(".selectivity-input .selectivity-single-select").each(function () {
          $(this).append(
            '<span class="selectivity-arrow"><svg class="alert__icon"><use xlink:href="/dist/img/svg-sprite.svg#sort-down-arrow"></use></svg></span>'
          );
        });
      },
      error: function error(err) {
        console.log("Data is not there: " + JSON.stringify(err));
      },
    });
  }
  $(document).on("change", "#ddlShippingCountry", function () {
    var Value = $(this)
      .find(".selectivity-single-selected-item")
      .attr("data-item-id");

    $.ajax({
      url: "/Account/api/ContactInfoApi/GetStates/",
      data: { Country: Value },
      type: "POST",
      success: function success(Data) {
        $("#ddlShippingState").remove();
        $('label[for="ShipState"]')
          .parent()
          .append('<select name="ShipState" id="ddlShippingState"></select>');

        var stateValue = $("#hiddenState").val();

        for (var key in Data) {
          if (stateValue === Data[key].ID) {
            $("#ddlShippingState").append(
              '<option selected value="' +
                Data[key].ID +
                '">' +
                Data[key].Name +
                "</option>"
            );
          } else {
            $("#ddlShippingState").append(
              '<option value="' +
                Data[key].ID +
                '">' +
                Data[key].Name +
                "</option>"
            );
          }
        }
        $("#ddlShippingState").selectivity({
          showSearchInputInDropdown: false,
        });
        $(".selectivity-input .selectivity-single-select").each(function () {
          $(this).append(
            '<span class="selectivity-arrow"><svg class="alert__icon"><use xlink:href="/dist/img/svg-sprite.svg#sort-down-arrow"></use></svg></span>'
          );
        });
      },
      error: function error(err) {
        console.log("Data is not there: " + JSON.stringify(err));
      },
    });
  });

  //IPMP-2486
  if ($(".myView-placeholder").length) {
    $(".header__wrapper").addClass("tabheader_wrapper");
    $(".header__logo").addClass("tabheader_logo");
    $(".header-publication-links").addClass("tabheader-publication");
    $(".header-account-right-access").addClass("tabheader-account");
  }

  // login details
  $(
    ".header-account-access__label a, .article-call-to-action--sign-in-up .js-login-container a"
  ).click(function (e) {
    e.preventDefault();
    var $this = $(this),
      href = $this.attr("href"),
      indexOfProcessId = href.indexOf("processId"),
      getId = href.substring(indexOfProcessId + 12),
      formcls = $this.closest(".form-save-search-login"),
      filterStr = "";
    if (formcls.find(".save-search-signin").length) {
      if (window.location.href.indexOf("#?") !== -1)
        var filterdata = window.location.href.split("#?")[1];
      if (filterdata) filterStr = "#?" + filterdata;
    }

    $.ajax({
      url: "/User/ProcessUserRequest/LoginLogger",
      type: "GET",
      context: this,
      data: {
        processId: getId,
        searchQuery: filterStr,
      },
      success: function () {},
      error: function (response) {
        return false;
      },
    });

    if ($(".myview-settings").length === 0) {
      window.location.href = href;
    } else if (
      $(".myview-settings").length &&
      ($("#validatePreference").val() === "0" ||
        $("#validatePriority").val() === "false")
    ) {
      window.location.href = href;
    }
  });

  $(".saved-preferences .sortable-table__col a").mousedown(function (event) {
    event.preventDefault();
    var anc = $(this),
      str = anc.attr("href"),
      splStr = str.split("="),
      customStr = "",
      singlequeryStr = "",
      mapObj = {
        "&": "%26",
        "#": "%23",
      },
      mapObjRev = {
        "%26": "&",
        "%23": "#",
      };
    if (splStr.length > 2) {
      if (splStr[1].indexOf("&page") != -1) {
        var str1 = splStr[1].replace("&page", "");
        customStr += str1.replace(/&|#/gi, function (matched) {
          return mapObj[matched];
        });
        var str2 = customStr + "&page";
        splStr.splice(1, 1);
        splStr.splice(1, 0, str2);
        var newurl = splStr.join("=");
        anc.attr("href", newurl);
      } else {
        customStr += splStr[1].replace(/%26|%23/gi, function (matched) {
          return mapObjRev[matched];
        });
        splStr.splice(1, 1);
        splStr.splice(1, 0, customStr);
        var newurl1 = splStr.join("=");
        anc.attr("href", newurl1);
      }
    } else {
      singlequeryStr += splStr[1].replace(/&|#/gi, function (matched) {
        return mapObj[matched];
      });
      var newurl1 = splStr[0] + "=" + singlequeryStr;
      anc.attr("href", newurl1);
    }
  });
});

$(window).on("load", function () {
  if (document.location.href.indexOf("search#?") === -1) {
    $(".badge").each(function () {
      var badgeBackgroundColor = $(this).data("background-color");
      var badgeTextColor = $(this).data("text-color");
      if (badgeBackgroundColor && badgeTextColor) {
        $(this).css({
          "background-color": badgeBackgroundColor,
          color: badgeTextColor,
        });
      }
    });
  }
});
