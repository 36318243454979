$(function () {
  var $items = $('.service-area-section'),
    $documentTitle = "",
    $documentId = "",
    $inPageListItem = '<li><a href=#{{id}}>{{documentTitle}}</a></li>',
    $listHtml = "",
    halfNavHeight,
    $navContainer,
    $prevNavContainer,
    $linkContainer = $('.inpage-nav-container .link-container'),
    $theamOmdia = $('body').hasClass('theme-omdia'),
    $htmlElem = $('html');

  if ($items.length && $linkContainer.length) {
    $.each($items, function (index, item) {
      $documentTitle = $(this).attr('data-title');
      $documentId = $(this).attr('id');
      $listHtml += $inPageListItem.replace(/{{id}}/g, $documentId)
        .replace(/{{documentTitle}}/g, $documentTitle);
    });

    $linkContainer[0].innerHTML = $listHtml;
    $htmlElem.css('scroll-behavior', 'smooth');
  }

 $(".featured-research-container, .asset_family_mod").each(function () {
    var $anchor = $(this).find(".click-utag");
    $(this).on("click", function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      if ($theamOmdia) {
        $anchor[0].click();
      }
    });
  });

  $items.find('.article-preview__byline').on('click', function (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
  });

  if ($theamOmdia) {
    // offset jump link nav so its always half way up its previous sibling (banner)
    $navContainer = $('.inpage-nav-container');
    if ($navContainer.length > 0) {
      $prevNavContainer = $navContainer.prev();
      halfNavHeight = $navContainer.height() / 2;
      $(window).on('resize', function() {
        halfNavHeight = $navContainer.height() / 2;
        $navContainer.css('margin-top', -halfNavHeight);
        $prevNavContainer.css('padding-bottom', halfNavHeight);
      });
      $(window).trigger('resize');
    }
  }
});