(function () {
  "use strict";

  var $tabsHeader = $(".tabs-header"),
    $tabsContent = $(".tabs-content"),
    $tabsContentChildren = $tabsContent.children();

  if ($tabsHeader.length === 0 || $tabsContent.length === 0) return;

  // init tabs
  $tabsHeader.children().each(function () {
    $(this).on("click", function () {
      var $tabsHeaderBtn = $(this),
        dataTargetTab,
        $targetTab;

      // early return if the active class already set
      if ($tabsHeaderBtn.hasClass("active")) return;

      // get targettab from 'data-target-tab' of tab header
      dataTargetTab = $tabsHeaderBtn.data("target-tab");
      $targetTab = $tabsContent.children("[data-tab-id=" + dataTargetTab + "]");

      // toggle active class for tab header
      $tabsHeader.children(".active").removeClass("active");
      $tabsHeaderBtn.addClass("active");

      // toggle active class for tab content
      $tabsContentChildren.removeClass("active");
      $targetTab.addClass("active");
    });
  });
})();
