﻿import FormController from '../controllers/form-controller';

$(document).ready(function () {
    var savedItems = document.getElementsByClassName("sortable-table__col--date");
    if (savedItems != null && savedItems.length > 0) {
        var counter;
        for (counter = 0; counter < savedItems.length; counter++) {
            if (savedItems[counter] != null && savedItems[counter].dataset != null) {
                var savedOn = new Date(savedItems[counter].dataset.savedondate);
                var nodeSavedOn = document.createElement("div");
                if (isNaN(savedOn.getTime()) || savedOn.getYear() <= 1) {
                    nodeSavedOn.appendChild(document.createTextNode(""));
                } else {
                    var localDate = savedOn.setMinutes(savedOn.getMinutes() + savedOn.getTimezoneOffset());
                    nodeSavedOn.appendChild(document.createTextNode(formatDate(localDate)));
                }
                savedItems[counter].appendChild(nodeSavedOn);
            }
        }
    }
});

function formatDate(localDate) {
    var formatDate = new Date(localDate);
    var monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sept", "Oct",
        "Nov", "Dec"
    ];

    var day = formatDate.getDate();
    var monthIndex = formatDate.getMonth();
    var year = formatDate.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}